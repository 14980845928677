.container {
    display: flex;
    flex-direction: column;
    min-height: 100dvh;   
    background-color: #F4F4F4;
    max-width: 500px; 
    margin: 0 auto;  
    position: relative;
    overflow: hidden; 
    background-image: url('../../../img/lenta-d.png'); 
    background-repeat: no-repeat;
    background-position: right 50dvh; 
    background-size: 160%;
}

@media (max-height: 800px) {
    .imageContainer {
        display: flex;
        justify-content: center; 
        align-items: center;
        border: none;
        height: 330px;
    }

    .miniStation {
        height: 330px;
    }
}

@media (max-height: 750px) {
    .imageContainer {
        display: flex;
        justify-content: center; 
        align-items: center;
        border: none;
        height: 300px;
    }

    .miniStation {
        height: 300px;
    }
}

@media (max-height: 700px) {
    .imageContainer {
        display: flex;
        justify-content: center; 
        align-items: center;
        border: none;
        height: 250px;
    }

    .miniStation {
        height: 250px;
    }
}

@media (max-height: 650px) {
    .imageContainer {
        display: flex;
        justify-content: center; 
        align-items: center;
        border: none;
        height: 220px;
    }

    .miniStation {
        height: 220px;
    }
}

@media (max-height: 620px) {
    .imageContainer {
        display: flex;
        justify-content: center; 
        align-items: center;
        border: none;
        height: 200px;
    }

    .miniStation {
        height: 200px;
    }
}

@media (max-height: 620px) {
    .imageContainer {
        display: flex;
        justify-content: center; 
        align-items: center;
        border: none;
        height: 180px;
    }

    .miniStation {
        height: 180px;
    }
}

@media (max-width: 370px) {
    .paramContainer {
        padding: 5px 10px 5px 10px !important;
    }

    .paramText {
        font-size: 14px;
    }
}

.section {
    display: flex;
    flex-direction: column;
    justify-content: center; 
    width: 88%;
    margin: 0 auto;
    align-items: center;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.sectionHeader {
    padding-bottom: 60px;
}

.hidden {
    display: none;
}

.mainSection { 
    display: flex;
    flex-direction: column;
    justify-content: center; 
    width: 88%;
    margin: 0 auto;
    /* flex-grow: 1; */
    background: linear-gradient(#D3D8EE, #B5BEE3);
    border-radius: 40px; 
}

.mainSectionOff { 
    display: flex;
    flex-direction: column;
    justify-content: center; 
    width: 88%;
    margin: 0 auto;
    /* flex-grow: 1; */
    background: linear-gradient(#e2e2e2, #c5c5c5);
    border-radius: 40px;
}

.card {
    display: flex;
    flex-direction: column;
    text-align: center;
}


.information {
    margin: 40px 0 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 40px;
}

.status {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px 10px 15px;
    background-color: #9cacec;
    border-radius: 40px;
    color: #ffffff;
}

.statusOff {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px 10px 15px;
    background-color: #c5c5c5;
    border-radius: 40px;
    color: #ffffff;
}

.statusCharging {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px 10px 15px;
    background-color: #8dc985;
    border-radius: 40px;
    color: #ffffff;
}

.statusFaulted {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px 10px 15px;
    background-color: #c59191;
    border-radius: 40px;
    color: #ffffff;
}

.statusFinishing {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px 10px 15px;
    background-color: #9ba0c9;
    border-radius: 40px;
    color: #ffffff;
}

.statusPrecharging {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px 10px 15px;
    background-color: #c9a884;
    border-radius: 40px;
    color: #ffffff;
}

.paramText {
    color: #ffffff;
    margin: 0;
}

.parameters {
    display: flex;
    flex-direction: row;
    justify-content: center; 
    align-items: center;
    gap: 10px;
}


.paramContainer {
    display: flex;
    padding: 10px 15px 10px 15px;
    background-color: #B5BEE3;
    border-radius: 40px;
}

.paramContainerOff {
    display: flex;
    padding: 10px 15px 10px 15px;
    background-color: #c5c5c5;
    border-radius: 40px;
}

.imageContainer {
    display: flex;
    justify-content: center; 
    align-items: center;
    border: none;
    pointer-events: none; 
}

.sectionHeader {
    height: 60px;
}


.titleText {
    font-size: 28px;
    font-weight: 500;
    margin: 0;
}

.submitButtonConnect {
    display: flex; /* Для горизонтального расположения текста и изображения */
    justify-content: center;
    align-items: center; /* Вертикальное выравнивание по центру */
    background-color: #1E1E1E;
    height: 64px;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 40px;
    width: 100%;
}

.submitButtonConnect:hover {
    background-color: #353435;
}

.textButtons {
    font-weight: 600;
    margin: 0;
}

.textStationName {
    margin-top: 10px;
    font-size: 24px;
    color: white;
}

.skipContainer {
    width: 100%;
}

.plusIcon {
    margin-left: 8px; /* Добавляет отступ между текстом и изображением */
    width: 20px; /* Ширина изображения */
    height: 20px; /* Высота изображения */ 
}

.sectionButtons {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 88%;
    margin: 0 auto;
    padding-top: 20px;
    padding-bottom: 30px;
}

/* Стили для точек */
:global(.slick-dots li) {
    margin: 0 15px; /* Увеличиваем расстояние между точками */
}

:global(.slick-dots li button) {
    width: 15px !important;
    height: 15px !important;
    border-radius: 50%;
    background-color: #B5BEE3;
    border: none;
    outline: none;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

/* Убираем серую точку внутри кнопки */
:global(.slick-dots li button::before) {
    /* color: #ffffff;
    background-color: #030303;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center; */
}

/* Стиль для активной точки */
:global(.slick-dots li.slick-active button) {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #202325;
    transform: scale(1.2); 
}

