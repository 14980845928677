.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100dvh;
    background-color: #F4F4F4;
    text-align: center;
    max-width: 500px; /* Максимальная ширина */
    margin: 0 auto; /* Центрирование компонента */
}

.imageWrapper {
    background-image: url('../../../img/glass.png'); /* Путь к вашему изображению */
    background-size: auto;
    background-position: left;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}

.content {
    padding: 0 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    font-size: 96px;
    font-weight: 400;
    margin: 0 auto;
    text-align: left; /* Выровнять текст по левому краю */
    width: 88%;
}

.description {
    font-size: 16px;
    color: #1E1E1E;
    margin: 10px 0 30px;
    text-align: left;  
    font-weight: 500;
    width: 88%;
}

.startButton {
    display: flex;
    justify-content: space-between; /* Разделяем текст и стрелку */
    align-items: center;
    background-color: #1E1E1E;
    color: #FFFFFF;
    height: 64px;
    padding: 12px 30px;
    font-size: 16px;
    border: none;
    border-radius: 40px;
    cursor: pointer;
    width: 88%;
    text-align: left; /* Выравнивание текста по левому краю */
}

.startButton:hover {
    background-color: #2b2b2b;
}

.arrow {
    margin-left: auto; /* Отталкиваем стрелку от текста */
    font-size: 20px;
}

.textButton {
    font-weight: 600;
    margin: 0;
}
