.container {
    display: flex;
    flex-direction: column;
    height: 100vh;   
    background-color: #f8f8f8;
    max-width: 500px; 
    margin: 0 auto;  
    background-color: #F4F4F4;
}

.section {
    flex: 1;  
    display: flex;
    flex-direction: column;
    justify-content: flex-start; 
    width: 88%;
    margin: 0 auto;
}

.sectionHeader {
    height: 60px;
}

.titleText {
    font-size: 28px;
    margin: 0;
}

.imgContainer {
    width: 100%;
}

.icon {
    width: 70px;
    height: 70px;
    object-fit: contain; /* Убедитесь, что изображение сохраняет пропорции */
}

.camContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; 
    background-color: #2c2c2c;
    border-radius: 8px;
}

.manualInputContainer {
    height: 64px;
    width: 100%;
}

.manualInput {
    height: 64px;
    width: 100%;
    border-radius: 16px;
    border: none;
    text-align: center;
    font-size: 22px; /* Размер текста, который вводится пользователем */
    font-weight: 600;
}

.sectionButton {
    flex: 1;  
    display: flex;
    flex-direction: row;
    justify-content: space-around; 
    width: 88%;
    margin: 0 auto;
    gap: 20px;
}

.submitButtonSkip {
    display: flex;
    justify-content: center;
    align-items: center; 
    padding: 10px 20px;
    /* background-color: #D7E6F5; */
    background-color: #ffffff;
    color: #1E1E1E;
    height: 64px;
    border: none;
    border-radius: 40px;
    cursor: not-allowed;
    font-size: 16px;
    text-align: center;
    position: relative;
    top: 5px;
    width: 100%;
    position: relative;
    top: 40px;
}

.submitButtonConnectDis {
    display: flex; /* Для горизонтального расположения текста и изображения */
    justify-content: center;
    align-items: center; /* Вертикальное выравнивание по центру */
    padding: 10px 20px;
    background-color: #949393;
    height: 64px;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 40px;
    position: relative;
    top: 5px;
    width: 100%;
    position: relative;
    top: 40px;
}

.submitButtonConnect {
    display: flex; /* Для горизонтального расположения текста и изображения */
    justify-content: center;
    align-items: center; /* Вертикальное выравнивание по центру */
    padding: 10px 20px;
    background-color: #1E1E1E;
    height: 64px;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 40px;
    position: relative;
    top: 5px;
    width: 100%;
    position: relative;
    top: 40px;
}

.submitButtonConnect:hover {
    background-color: #353435;
}

.textButtons {
    font-weight: 600;
    margin: 0;
}

.skipContainer {
    width: 100%;
}

.mobileSignal {
    margin-left: 8px; /* Добавляет отступ между текстом и изображением */
    width: 16px; /* Ширина изображения */
    height: 16px; /* Высота изображения */
}
