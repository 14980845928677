.container {
    display: flex;
    flex-direction: column;
    height: 100vh;   
    max-width: 500px; 
    margin: 0 auto;  
    background-color: #F4F4F4;
}

.section {
    flex: 1;  
    display: flex;
    flex-direction: column;
    justify-content: flex-start; 
    align-items: center; 
}

@media (max-height: 600px) {
    .titleBigText {
        font-size: 24px !important; 
    }
}

@media (max-height: 400px) {
    .titleBigText {
        font-size: 24px !important; 
    }

    .textPhoneDiv {
        margin: 0 !important;
    }

    .selectCountryFlagDiv {
        margin: 0 ;
    }

    .smsButton {
        height: 40px !important;
    }

    .smsButtonActive {
        height: 40px !important;
    }

    .vkButton {
        height: 40px !important;
    }

    .selectCountryFlagDiv {
        height: 40px !important;
    }

    .input {
        height: 40px !important;
    }

    .inputSuccess {
        height: 40px !important;
    }
}

.sectionPhone {
    flex: 1;         
    display: flex;
    justify-content: center;
    align-items: center;  
}

.sectionOr {          
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
}

.sectionSocial {
    flex: 1;      
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttons {
    margin-top: 5px;
    border-radius: 16px;
    height: 64px;
    background-color: #dee7fc;
    background-size: contain;
    background-repeat: round;
}

.vkButton {
    height: 64px;
    background-image: url("../../../img/vkid.svg");
    background-repeat: no-repeat;
    text-align: center;
    background-size: 100% 100%; 
    width: 88%;
}

.appleButton {
    background-image: url("../../../img/apple.svg");
}

.googleButton {
    background-image: url("../../../img/google.svg");
}

.emailButton {
    background-image: url("../../../img/email.svg");
}

.or {
    height: 30px;
    background-image: url("../../../img/or.svg");
    background-repeat: no-repeat;
    text-align: center;
    background-size: 100% 100%;
    width: 88%;
}

.privacyText {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 12px;
    padding: 25px 0;
}

.smsButton {
    background: #ffffff;
    border-radius: 8px;
    font-family: inherit;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    border: none;
    color: #797979;
    width: 88%;
    height: 64px;
    border-radius: 16px;
}

.vkButton {
    border: 1px solid #F4F4F4;
    background: #D7E6F5;
    border-radius: 16px;
    font-family: inherit;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    border: none;
    width: 88%;
}


.vkButton:hover {
    background: #bbd8f5;
}

.smsButtonActive {
    background: #1e1e1e;
    border-radius: 8px;
    font-family: inherit;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    border: none;
    color: #ffffff;
    width: 88%;
    height: 64px;
    border-radius: 16px;
}

.smsButtonActive:hover {
    background-color: #353435;
}

.imgsvgor {
    width: 100%;
    height: 12px;
    margin-top: 20px;
}

.textPhoneDiv {
    font-weight: 500;
    align-items: end;
    margin-top: 30px;
    width: 88%;
    font-size: 16px;
}

.titleText {
    font-size: 28px;
    font-weight: 500;
    align-items: end;
    margin-top: 30px;
    width: 88%;
}

.phoneContainer {
    display: flex;
    align-items: center;
    width: 88%;
    margin: 0 auto;
    gap: 10px;
    margin-bottom: 10px;
}

.selectCountryFlagDiv {
    display: flex;
    align-items: center;
    background: #ffffff;
    border-radius: 16px;
    padding-left: 15px;
    padding-right: 10px;
    height: 64px;
}

.inputPhoneDiv {
        width: 100%;
}

.input {
    background: #ffffff;
    border-radius: 16px;
    border: none;
    border-radius: 16px;
    font-size: 16px;
    width: 100%;
    height: 64px;
    padding-left: 12px;
    padding-right: 12px;
}

.input:focus {
    outline: none;
}

.inputSuccess {
    padding-left: 12px;
    padding-right: 12px;
    border: none;
    background: #ffffff url(../../../img/check.svg) no-repeat right;
    background-position: calc(100% - 16px) center;
    border-radius: 16px;
    font-size: 16px;
    width: 100%;
    height: 64px;
}


.titleBigText {
    font-size: 32px;
    font-weight: 500;
}

.vkLogo {
    width: 45px;
    height: 30px; 
    vertical-align: middle; 
}

.sectionPrivacy {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
}
