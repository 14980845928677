.container {
    display: flex;
    flex-direction: column;
    height: 100vh;   
    background-color: #f8f8f8;
    background-image: url('../../../img/lenta-d.png'); 
    background-repeat: no-repeat;
    background-position: center 50dvh; 
    background-size: 80%;
    max-width: 500px; 
    margin: 0 auto;  
    background-color: #F4F4F4;
}

@media (max-height: 770px) {
    .container {
        background-position: center 40dvh; 
    }
}

@media (max-height: 770px) {
    .container {
        background-position: center 30dvh; 
    }
}

.section {
    flex: 1;  
    display: flex;
    flex-direction: column;
    justify-content: flex-start; 
    width: 88%;
    margin: 0 auto;
}

.sectionButtons {
    flex: 1;  
    display: flex;
    flex-direction: column;
    justify-content: flex-end; 
    width: 88%;
    margin: 0 auto;
    margin-bottom: 30px;
}

.sectionHeader {
    height: 60px;
}

.submitButtonEnabled {
    display: flex;
    justify-content: space-between;
    align-items: center; 
    padding: 12px 24px;
    height: 64px;
    background-color: #1E1E1E;
    color: #fff;
    border: none;
    border-radius: 40px;
    cursor: not-allowed;
    font-size: 16px;
    width: 100%;
    text-align: left;
}

.submitButtonEnabled:hover {
    background-color: #353435;
}

.arrow {
    font-size: 20px;
    color: #ffffff;
    margin-left: auto;
}