.container {
    display: flex;
    flex-direction: column; /* Вертикальная ориентация */
    height: 100vh;          /* Высота контейнера на весь экран */
    max-width: 500px; 
    margin: 0 auto;  
    background: url("../../../../img/lenta-wb.png") no-repeat bottom;
    background-color: #F4F4F4;
}

.section {
    flex: 1;                /* Равное распределение высоты между секциями */
    display: flex;
    justify-content: center; /* Центрирование содержимого по горизонтали */
    align-items: center;    /* Центрирование содержимого по вертикали */
}

.titleText {
    font-size: 28px;
    font-weight: 500;
    position: relative;
    bottom: 30px;
    right: 15px;
}

.otpInlineInput {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
}

.otpInput {
    width: 60px;
    font-size: 56px;
    border: 0 none;
    appearance: none;
    text-align: center;
    transition: all 0.2s;
    background: transparent;
    border-bottom: 2px solid;
    border-color: #817f7f;
}

.sectionButtons {
    display: flex;
    flex-grow: 1;
    width: 88%;
    margin: 0 auto;
    align-items: flex-end;
    padding-bottom: 30px;
}

.button {
    height: 64px;
    width: 151px;
    background-color: #D7E6F5;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-left: 16px;
    padding-right: 16px;
}

.button:hover {
    background-color: #add4fd;
}

.buttonText {
    margin: 0;
}

.arrow {
    height: 24px;
    width: 24px;
}