.container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    background-color: #F4F4F4;
    max-width: 500px;
    margin: 0 auto;
    min-height: 100dvh;
}

.sectionTitle {
    text-align: center;
    margin-bottom: 20px;
}

.titleText {
    font-size: 28px;

}

.mainSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

.timeInputContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 88%;
    margin: 0 auto;
    gap: 10px;
}

.timeLabel {
    font-size: 16px;
    font-weight: 500;
}

.otpInlineInput {
    display: flex;
    gap: 10px;
}

.otpInput {
    width: 60px;
    font-size: 56px;
    border: 0 none;
    appearance: none;
    text-align: center;
    transition: all 0.2s;
    background: transparent;
    border-bottom: 2px solid;
    border-color: #817f7f;
}

.errorText {
    color: red;
    font-size: 14px;
    text-align: center;
}

.sectionButtons {
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
    padding-top: 20px;
    padding-bottom: 30px;
    width: 88%;
    margin: 0 auto;
}

.submitStartCharging {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1E1E1E;
    height: 64px;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 40px;
    width: 100%;
}

.submitStartCharging:hover {
    background-color: #353435;
}

.textButtons {
    margin: 0;
}

.sliderContainer {
    position: relative;
    flex: 1;
    padding-right: 10px;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 6px;
  border-radius: 5px;
  background: linear-gradient(to right, #7f81db 50%, #b7c3ec 50%); /* Цвет заполненной и пустой части */
  outline: none;
  transition: background 0.3s;
}

.slider::-webkit-slider-runnable-track {
  height: 6px;
  border-radius: 5px;
  background: transparent; /* Фон отключаем */
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #474a6d; /* Цвет кнопки-ползунка */
  cursor: pointer;
  position: relative;
  margin-top: -6px; /* Поднимаем курсор */
}

.scale {
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
}

.scaleMark {
    position: relative;
    width: 1px;
    height: 10px;
    background-color: #333;
}

.scaleLabel {
    position: absolute;
    top: 12px;
    font-size: 12px;
    text-align: center;
    transform: translateX(-50%);
}

.maxPowerContainer {
    padding-top: 40px;
    display: flex;
    display: row;
    margin-right: 5px;
    margin-left: 5px;
    /* margin: 15px; */
    padding-bottom: 20px;
    width: 88%;
    margin: 0 auto;
}

.currentValue {
    display: flex;
    justify-content: center;
    background-color: #ffffff;
    border-radius: 16px;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    width: 82px;
}

.textMaxPower {
    margin: 0;
}

.weekContainer {
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 7px; /* Расстояние между днями недели */
    width: 88%;
    margin: 0 auto;
}

.day {
    padding: 10px 15px 10px 15px;
    border: 1px solid #ccc;
    cursor: pointer;
    text-align: center;
    user-select: none; /* Чтобы текст нельзя было выделить */
    border-radius: 8px;
}

.activeDay {
    background-color: #61ce6c; /* Зеленый цвет для активного дня */
    color: white; /* Белый текст для контраста */
}