.container {
    display: flex;
    flex-direction: column;
    background-color: #F4F4F4;
    max-width: 500px;
    margin: 0 auto;
    min-height: 100vh;
}

.sectionTitle {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: 20px;
}

.titleText {
    font-size: 28px;
    margin: 0;
}

.mainSection {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 88%;
    margin: 0 auto;
}

.card {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border-radius: 16px;
    padding: 16px;
    height: 64px;
}

.card:hover {
    background-color: #cadbfa;
}

.icon {
    padding-right: 10px;
}

.text {
    margin: 0;
    font-weight: 600;
}

.bottomSection {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 30px;
}