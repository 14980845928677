* {
    padding: 0;
    margin: 0;
    font-family: "Inter Tight", serif;
    font-weight: 500;
}

body {
    --react-international-phone-country-selector-border-color: #F4F4F4;
    --react-international-phone-border-color: #F4F4F4;
    margin: 0;
}


