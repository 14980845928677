.container {
    display: flex;
    flex-direction: column;
    background-color: #F4F4F4;
    max-width: 500px;
    margin: 0 auto;
    min-height: 100dvh;
}

.sectionTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.mainSection {
    display: flex;
    flex-direction: column;
    width: 88%;
    margin: 0 auto;
    margin-top: 10px;
    flex-grow: 1;
}

.sectionButtons {
    gap: 10px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    width: 88%;
    margin: 0 auto;
    padding-top: 20px;
    padding-bottom: 30px;
    margin-top: 15px;
}

.titleText {
    font-size: 28px;
    margin: 0;
}

.phoneContainer {
    display: flex;
    align-items: center;
    width: 88%;
    margin: 0 auto;
    gap: 10px;
    margin-bottom: 10px;
}

.selectCountryFlagDiv {
    display: flex;
    align-items: center;
    background: #ffffff;
    border-radius: 16px;
    padding-left: 15px;
    padding-right: 10px;
    height: 64px;
}

.inputPhoneDiv {
        width: 100%;
}

.input {
    background: #ffffff;
    border-radius: 16px;
    border: none;
    border-radius: 16px;
    font-size: 16px;
    width: 100%;
    height: 64px;
    padding-left: 12px;
    padding-right: 12px;
}

.inputSuccess {
    padding-left: 12px;
    padding-right: 12px;
    border: none;
    background: #ffffff url(../../../img/check.svg) no-repeat right;
    background-position: calc(100% - 16px) center;
    border-radius: 16px;
    font-size: 16px;
    width: 100%;
    height: 64px;
}

.submitButtonConnect {
    display: flex; /* Для горизонтального расположения текста и изображения */
    justify-content: center;
    align-items: center; /* Вертикальное выравнивание по центру */
    padding: 10px 20px;
    background-color: #1E1E1E;
    height: 64px;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 40px;
    position: relative;
    top: 5px;
    width: 100%;
    position: relative;
    top: 40px;
}

.submitButtonConnectDis {
    display: flex; /* Для горизонтального расположения текста и изображения */
    justify-content: center;
    align-items: center; /* Вертикальное выравнивание по центру */
    padding: 10px 20px;
    background-color: #949393;
    height: 64px;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 40px;
    position: relative;
    top: 5px;
    width: 100%;
    position: relative;
    top: 40px;
}

.textButtons {
    font-weight: 600;
    margin: 0;
}

.mobileSignal {
    margin-left: 8px; /* Добавляет отступ между текстом и изображением */
    width: 16px; /* Ширина изображения */
    height: 16px; /* Высота изображения */
}