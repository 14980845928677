.container {
    display: flex;
    flex-direction: column;
    background-color: #F4F4F4;
    max-width: 500px;
    margin: 0 auto;
    min-height: 100vh;
    background-image: url('../../../img/lenta-d.png'); 
    background-repeat: no-repeat;
    background-position: center 40dvh; 
}

.sectionTitle {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: 20px;
}

.titleText {
    font-size: 28px;
    margin: 0;
}