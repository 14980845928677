.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100dvh;   
    background-color: #F4F4F4;
    max-width: 500px; 
    margin: 0 auto;  
    position: relative;
    overflow: hidden; 
    background-image: url('../../img/lenta-d.png'); 
    background-repeat: no-repeat;
    background-position: right 50dvh; 
    background-size: 160%;
}

.titleText {
    font-size: 28px;
    align-items: center;
}

.mainText {
    text-align: center;
}
