.container {
    display: flex;
    flex-direction: column;
    background-color: #F4F4F4;
    max-width: 500px;
    margin: 0 auto;
    min-height: 100vh;
}

.sectionTitle {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: 20px;
}

.titleText {
    font-size: 28px;
    margin: 0;
}

.mainSection {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 88%;
    margin: 0 auto;
}

.languageCard {
    background-color: #ffffff;
    width: 88%;
    margin: 0 auto;
    border-radius: 16px;
    padding: 16px;
    display: flex;
    align-items: center;  
    justify-content: space-between;
}

.languageCard:hover {
    background-color: #cadbfa;
}

.icon {
    padding-right: 10px;
}

.text {
    margin: 0;
    font-weight: 600;
}

.point {
    background-color: #D9D9D9;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.getPoint {
    background-color: #000000;
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.setPoint {
    background-color: #ffffff;
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

