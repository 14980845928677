.container {
    display: flex;
    flex-direction: column;
    background-color: #F4F4F4;
    max-width: 500px;
    margin: 0 auto;
    min-height: 100vh;
}

.sectionTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 88%;
    margin: 0 auto;
    padding-bottom: 10px;
    padding-top: 10px;
}

.titleText {
    font-size: 24px;
    margin: 0;
}

.mainSection {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.card {
    display: flex;
    flex-wrap: wrap;
    background-color: #ffffff;
    width: 88%;
    margin: 0 auto;
    border-radius: 16px;
    padding: 16px;

}

.stationName {
    width: 50%;

}

.sessionDate {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    text-align: end;
}

.chargingData {
    width: 50%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.image {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.textParam {
    margin: 0;
    font-weight: 600;
}

.chargeIcon {
    width: 48px;
    height: 48px;
}

.sectionButtons {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 88%;
    margin: 0 auto;
    z-index: 2;
    padding-bottom: 80px;
}