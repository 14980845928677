.container {
    display: flex;
    flex-direction: column;
    height: 100vh;   
    background-color: #f8f8f8;
    background-size: 160%;
    max-width: 500px; 
    margin: 0 auto;  
    background-color: #F4F4F4;
    background-image: url('../../../img/lenta-d.png'); 
    background-repeat: no-repeat;
    background-position: right 50dvh; 
    background-size: 160%;
}

.titleText {
    font-size: 28px;
}

.section {
    flex: 1;  
    display: flex;
    flex-direction: column;
    justify-content: center; 
    width: 88%;
    margin: 0 auto;
}

.sectionButton {
    flex: 1;  
    display: flex;
    align-items: flex-end;
    width: 88%;
    margin: 0 auto;
    gap: 20px;
    padding-bottom: 30px;
}

.sectionHeader {
    height: 60px;
}

.textButtons {
    font-weight: 600;
    margin: 0;
}

.mainImage {
    display: flex;
    justify-content: center;
    margin: 0 auto;
}

.wifiLight {
    border-radius: 40px;
    width: 100%;
}

.submitButtonEnabled {
    display: flex;
    justify-content: space-between;
    align-items: center; 
    padding: 12px 24px;
    height: 64px;
    background-color: #1E1E1E;
    color: #fff;
    border: none;
    border-radius: 40px;
    font-size: 16px;
    width: 100%;
    text-align: left;
}

.submitButtonEnabled:hover {
    background-color: #353435;
}

.arrow {
    font-size: 20px;
    color: #ffffff;
    margin-left: auto;
}

@media (max-height: 650px) {
    .mainImage {
        display: flex;
        justify-content: center;
        width: 80%;
        margin: 0 auto;
    }
}

@media (max-height: 590px) {
    .mainImage {
        display: flex;
        justify-content: center;
        width: 70%;
        margin: 0 auto;
    }

    .sectionButton {
        flex: 1;  
        display: flex;
        align-items: flex-end;
        width: 88%;
        margin: 0 auto;
        gap: 20px;
        padding-bottom: 10px;
    }
}

@media (max-height: 540px) {
    .mainImage {
        display: flex;
        justify-content: center;
        width: 50%;
        margin: 0 auto;
    }

    .sectionButton {
        flex: 1;  
        display: flex;
        align-items: flex-end;
        width: 88%;
        margin: 0 auto;
        gap: 20px;
        padding-bottom: 10px;
    }
}